import React from "react"

import SEO from "../components/seo"
import loadable from '@loadable/component'

// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import { AttachMoney, Home, Check, HowToReg as HowToRegIcon } from '@material-ui/icons';

const TusDatos = loadable(() => import("../components/pages/TusDatos"))

const iconos = { 1: <Home />, 2: <HowToRegIcon />, 3: <Check />, 4: <AttachMoney /> }
// const texts = [{ texto: 'A' }, { texto: 'B' }, { texto: 'C' }, { texto: 'D' }]
// const currentStep = 1

const TusDatosPage = ({ pageContext, location }) => (

  <LayoutsComponent
    layout={pageContext.layout}
    broker={pageContext.broker}
    location={location}
    companyName={pageContext.company}
    pageContext={pageContext}
    gradient
    currentStep={pageContext.step}
    iconos={iconos}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <TusDatos pageContext={pageContext} />
      </>
    )}
  />
)

export default TusDatosPage